import React, { useContext, useEffect, useState } from 'react';
import {Dialog, Container, Typography, DialogTitle, DialogContent, DialogActions, Button} from '@mui/material';

import app from '../config/firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import { populateUserCacheIfMissing, streamGroups } from '../utils/db_helper';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import GroupCreator from '../components/GroupCreator';
import { doDeleteGroup } from '../utils/function_helper';
import { GroupCard } from '../components/GroupCard';
import { AppContext } from '../config/AppContext';

const auth = getAuth(app);

export default function Home() {
    const [user, loading, error] = useAuthState(auth);
    const [groups, setGroups] = useState([]);
    const [working, setWorking] = useState(false);
    const [groupIdToDelete, setGroupIdToDelete] = useState(null);
    const [errorMessage, setErrorMessage] = useState(null)
    const { enqueueSnackbar } = useSnackbar()
    const {setUserLookup} = useContext(AppContext) || {};
    const navigate = useNavigate()
    
    const title = "Reading Groups";
    const deleteConfirmIsShowing = groupIdToDelete != null;

    useEffect(() => {
        if (user == null) {
            if (!loading) {
                console.log("User not signed in ")
                navigate("/login");    
            }
            return;
        }

        const unsubscribe = streamGroups(user,
            (querySnapshot) => {
                let allUserIds = [user.uid]
                const updatedGroups = 
                querySnapshot.docs.map(docSnapshot => {
                    allUserIds.concat(docSnapshot.data.userIds)
                    return {
                        ...docSnapshot.data(),
                        id: docSnapshot.id
                    }
                });
                setGroups(updatedGroups);
                populateUserCacheIfMissing(allUserIds, [], setUserLookup)
            },
            (error) => {
                console.log(error)
                enqueueSnackbar('Failure getting items')
                setErrorMessage('list-item-get-fail')
            }
        );
        return unsubscribe;
    }, [user, loading]);    
    
    const cleanDeleteGroup = () => {
        if (groupIdToDelete != null) {
            setWorking(true)
            enqueueSnackbar(`Deleting group...`);
            doDeleteGroup(groupIdToDelete).then(() => {
                enqueueSnackbar("Deleted group");
                setWorking(false)
                dismissDleteConfirmation();
            })    
        }
    }

    const showDeleteConfirmation = (group) => {
        setGroupIdToDelete(group.id);
    }

    const dismissDleteConfirmation = () => {
        setGroupIdToDelete(null);
    }

    return (
        <Container maxWidth="md" style={{paddingBottom: 50}}>
            <Typography variant="h3">{ title }</Typography>
            
            { groups.map((group) => {
                return <GroupCard key={group.id} 
                            group={group} 
                            user={user} 
                            working={working} 
                            onDeleteRequest={(group) => showDeleteConfirmation(group)} />
            })}

            <GroupCreator user={user} />

            <Dialog open={deleteConfirmIsShowing} onClose={dismissDleteConfirmation}>
                <DialogTitle>Really delete group and all notes?</DialogTitle>
                <DialogContent>
                    This can't be undone and will delete the group for all participants.
                </DialogContent>
                <DialogActions>
                    <Button style={styles.deleteButton} onClick={dismissDleteConfirmation}>Cancel</Button>
                    <Button variant="outlined" 
                        color="danger" 
                        disabled={working}
                        onClick={cleanDeleteGroup}>Delete</Button>
                </DialogActions>
            </Dialog>
                
        </Container>
    )
}

const styles = {
    deleteButton: {

    }
}