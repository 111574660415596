import { Avatar, AvatarGroup, Box, Button, Grid, LinearProgress, List, ListItem, ListItemAvatar, ListItemText, Stack, linearProgressClasses } from '@mui/material';
import React, { useEffect, useState } from 'react';
import isPropValid from '@emotion/is-prop-valid'
import UserAvatar from './UserAvatar';
import util from '../utils/util';
import styled from '@emotion/styled';


const BorderLinearProgress = styled(LinearProgress, { shouldForwardProp: (prop) => prop !== "barColor" })(({ theme, barColor }) => ({
    height: 5,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor: theme.palette.grey[400],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: barColor
    },
  }));

function UserProgressRow({size, uid, color, progress}) {
    return (
        <Grid container key={uid} style={{marginTop: "5px"}}>
            <Grid item xs={3}>
                <UserAvatar size={size} user_id={uid} align="right" />
            </Grid>
            <Grid item xs={9} style={{marginTop: "12px"}}>
                <BorderLinearProgress variant="determinate" value={progress} barColor={color} />            
            </Grid>
        </Grid>
    )
}

export default function ParticipantList({ user_ids, size, nSections, showProgress, userProgress }) {
    let avatars
    if (showProgress) {
        // Show linear progress for each user
        avatars = (
            <div className="userProgress" style={{width: '100%'}}>
                { user_ids.map((uid) => {
                    const userSection = userProgress[uid] || 0;
                    const progress = parseInt(100 * userSection / nSections);
                    const color = util.stringToColor(uid);
                    const label = `Section ${userSection}`;
                    return (
                        <UserProgressRow key={uid} uid={uid} title={label} progress={progress} color={color} size={size} />
                    )
                })}
            </div>
        )
    } else {
        avatars = (
            <AvatarGroup>
                { user_ids.map((user_id) => {
                    return <UserAvatar key={user_id} size={size} user_id={user_id} />
                })}
            </AvatarGroup>
        )
    }
    return (
        <Box 
            display="flex" 
            style={{marginTop: 10, marginBottom: 10}}
            justifyContent="center"
            >
            { avatars }
        </Box>
    )
}

ParticipantList.defaultProps = {
    user_ids: [],
    size: 34,
    userProgress: {},
    showProgress: true
}