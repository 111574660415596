import React, { useEffect, useRef, useState } from 'react';
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Container, Select, MenuItem, Typography, Chip, Badge, Box} from '@mui/material';

import { createNote, sendMessage, streamMessages, streamNotes } from '../utils/db_helper';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import app from '../config/firebase';
import ChatMessage from './ChatMessage';
import { Send } from '@mui/icons-material';
const auth = getAuth(app);


export default function GroupChat({ groupId, startMinimized }) {
    const [messages, setMessages] = useState([]);
    const [messageText, setMessageText] = useState("")
    const [user, loading, error] = useAuthState(auth);
    const [minimized, setMinimized] = useState(startMinimized)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const navigate = useNavigate()
    
    const bottomRef = useRef(null);

    useEffect(() => {
        const unsubscribe = streamMessages(groupId, 
            (querySnapshot) => {
                const items = 
                querySnapshot.docs.reverse().map(docSnapshot => {
                    return {
                        ...docSnapshot.data(),
                        id: docSnapshot.id
                    }
                });
                setMessages(items);
            },
            (error) => {
                console.log(error)
                enqueueSnackbar('Failure getting items')
            }
        );
        return unsubscribe;
    }, [groupId]);    

    useEffect(() => {
        if (!minimized) scrollBottom();
    }, [messages]);

    const sendNewMessage = () => {
        sendMessage(user, groupId, messageText).then((msg) => {
            setMessageText("")
        })
    }

    const scrollBottom = () => {
        bottomRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
    

    const handleNoteChange = (value) => {
        setMessageText(value)
    }

    const canSend = messageText.length > 0

    if (minimized) {
        return (
            <Box
                display="flex" 
                justifyContent="center"
                alignItems="center"
                style={{marginTop: 30, cursor: "pointer"}}
                >
                <Badge badgeContent={ messages.length } invisible={messages.length == 0} color="primary">
                    <Chip label="Chat" variant="outlined" onClick={() => setMinimized(false)} />
                </Badge>
            </Box>
        )
    } else {
        return (
            <div className="Chat" autoComplete="off">
    
                <Typography variant="h5" gutterBottom onClick={() => setMinimized(true)}>Chat</Typography>
    
                <Paper style={styles.listContainer} hidden={messages.length == 0}>
                    <List dense>
                        { messages.map((message) => {
                            return <ChatMessage key={message.id} message={message} />
                        })}
                    </List>
                    <div ref={bottomRef} />
                </Paper>
    
                <Typography variant='h6' gutterBottom>Send Message</Typography>
                <TextField 
                    value={messageText || ""} 
                    onChange={(e) => handleNoteChange(e.target.value)} 
                    placeholder="Enter a message..."
                    multiline
                    fullWidth />
                <Button variant="contained" 
                    style={styles.button}
                    disabled={!canSend}
                    onClick={sendNewMessage} 
                    startIcon={<Send />}>Send</Button>
    
            </div>
        )
    }

}

const styles = {
    button: {
        marginTop: '10px'
    },
    listContainer: {
        maxHeight: 400,
        overflowY: "scroll",
        marginBottom: 20
    }
}

GroupChat.defaultProps = {
    startMinimized: true
}