import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../config/AppContext';
import util from '../utils/util';
import UserAvatar from './UserAvatar';


export default function ChatMessage({ message }) {
    const userLookup = useContext(AppContext).userLookup;

    let timeStr = util.printDate(message.tsSent);
    return (
        <ListItem key={message.id}>
            <ListItemIcon>
                <UserAvatar user_id={message.userId} size={23} />
            </ListItemIcon>
            <ListItemText primary={message.text} secondary={timeStr}></ListItemText>
        </ListItem>
    )
}