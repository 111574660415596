import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';


const styles = {
    tf: {
        marginBottom: 10
    }
}

export default function Login({ theme }) {
    const navigate = useNavigate();

    return (
        <Container maxWidth="md">

            <Typography variant="h3">Login</Typography>

            <LoginForm />

        </Container>
    );
}
