import React, { useEffect, useState } from 'react';
import {Button, Alert, Fab, Box} from '@mui/material';

import { streamNotes } from '../utils/db_helper';
import { useSnackbar } from 'notistack';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import app from '../config/firebase';
import SectionNote from './SectionNote';
import NoteCreator from './NoteCreator';

const auth = getAuth(app);

export default function SectionNotes({ group, sectionId, sectionLabel }) {
    const [notes, setNotes] = useState([]);
    const [replyNotes, setReplyNotes] = useState({}) // noteId -> [replies]
    const [noteCreatorShowing, setNoteCreatorShowing] = useState(false);
    const [user, loading, error] = useAuthState(auth);
    const [replyingToNoteId, setReplyingToNoteId] = useState(null);
    const { enqueueSnackbar } = useSnackbar()
    
    const groupId = group.id

    useEffect(() => {
        const unsubscribe = streamNotes(groupId, sectionId,
            (querySnapshot) => {
                processNotesAndNestReplies(querySnapshot.docs);
            },
            (error) => {
                console.log(error)
                enqueueSnackbar('Failure getting items')
            }
        );
        return unsubscribe;
    }, [groupId, sectionId]);    

    const processNotesAndNestReplies = (noteDocs) => {
        const _noteReplies = {};
        const topLevelNotes = [];
        noteDocs.forEach(docSnapshot => {
            const noteData = {
                ...docSnapshot.data(),
                id: docSnapshot.id
            }
            const isReply = noteData.replyToNoteId != null;
            if (!isReply) {
                topLevelNotes.push(noteData)
            } else {
                if (_noteReplies[noteData.replyToNoteId] == null) {
                    _noteReplies[noteData.replyToNoteId] = []
                }
                _noteReplies[noteData.replyToNoteId].push(noteData);
            }
        });
        setNotes(topLevelNotes);
        setReplyNotes(_noteReplies);
    }
    
    const noNotes = notes.length === 0;

    return (
        <div style={{marginTop: 20}}>

            { notes.map((note) => {
                const isMine = note.userId === user.uid
                const replies = replyNotes[note.id] || []
                let newReplySec
                if (replyingToNoteId != null && replyingToNoteId === note.id) {
                    newReplySec = (
                        <NoteCreator group={group} sectionId={sectionId} 
                            showing={true} 
                            buttonLabel="Add Reply"
                            replyToNoteId={note.id} 
                            onNoteCreated={() => setReplyingToNoteId(null)}
                            onCreationCancelled={() => setReplyingToNoteId(null)} />
                    )
                }
                return (
                    <div key={note.id}>
                        <SectionNote groupId={groupId} note={note} isMine={isMine} onClickReply={() => setReplyingToNoteId(note.id)} />
                        { replies.map((replyNote) => {
                            const replyMine = replyNote.userId == user.uid;
                            return <SectionNote key={replyNote.id} groupId={groupId} note={replyNote} isMine={replyMine} />
                        })}
                        { newReplySec }
                    </div>
                )
            })}

            { noNotes ? <Alert severity="info">No notes yet in this {sectionLabel.toLowerCase()}, create the first.</Alert> : null }

            <div hidden={noteCreatorShowing}>
                <Box
                    display="flex" 
                    justifyContent="flex-end"
                    alignItems="flex-end"
                    >
                    <Fab variant="extended" style={styles.fab} onClick={() => setNoteCreatorShowing(true)} color="primary" >
                        Add Note
                    </Fab>                    
                </Box>
            </div>

            <NoteCreator group={group} 
                sectionId={sectionId} 
                showing={noteCreatorShowing}
                onCreationCancelled={() => setNoteCreatorShowing(false)} />
            

        </div>
    )
}

const styles = {
    fab: {
        marginTop: 10,

    },
    button: {
        marginTop: '10px'
    },
}