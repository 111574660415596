import { Settings } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, IconButton, MenuItem, Select, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { updateGroup } from '../utils/db_helper';
import { enqueueSnackbar } from 'notistack';


export default function SectionNav({ groupId, sectionLabel, sectionsWithNotes,
        nSections, active, onChangeSection }) {
    const [dialogShowing, setDialogShowing] = useState(false);
    const [numChapters, setNumChapters] = useState(nSections);
    let sections = Array.from({length: nSections}, (_, i) => i + 1) // [1, 2, ..., nSections]

    const handleChange = (e) => {
        onChangeSection(e.target.value);
    }

    const dismiss = () => {
        setDialogShowing(false)
    }

    const save = () => {
        let intNum = 0
        try {
            intNum = parseInt(numChapters);
        } catch (e) {

        }
        if (intNum > 1 && intNum < 40) {
            updateGroup(groupId, {
                'configuration.nSections': parseInt(numChapters)
            }).then(() => {
                dismiss();
            })    
        } else {
            enqueueSnackbar("Invalid number of sections")
        }
    }

    return (
        <div>
            <FormControl size="small">
                <Select onChange={(e) => handleChange(e)} value={active}>
                    { sections.map((sec) => {
                        const hasNote = sectionsWithNotes != null && sectionsWithNotes.indexOf(sec) > -1;
                        const noteIcon = hasNote ? " 🗒" : "";
                        return <MenuItem key={sec} value={sec}>{`${sectionLabel} ${sec}${noteIcon}`}</MenuItem>
                    }) }
                </Select>
            </FormControl>
            <IconButton onClick={() => setDialogShowing(true)}>
                <Settings />
            </IconButton>

            <Dialog open={dialogShowing} onClose={dismiss}>
                <DialogTitle>Number of { sectionLabel }s</DialogTitle>
                <DialogContent>
                    <TextField type="number" value={numChapters} onChange={(e) => setNumChapters(e.target.value) } />
                </DialogContent>
                <DialogActions>
                    <Button onClick={dismiss}>Cancel</Button>
                    <Button onClick={save} variant="outlined">Save</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

SectionNav.defaultProps = {
    nSections: 1
}