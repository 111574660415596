import { Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import { useState } from "react"
import { searchOpenLibrary } from "../services/open_library"
import { Check } from "@mui/icons-material"


export default function BookSelector({ onBookSelected, clearResultsOnSelect, searchResultLimit }) {
    const [suggestions, setSuggestions] = useState([])
    const [selectedBook, setSelectedBook] = useState(null)
    const [selectionType, setSelectionType] = useState("search");
    const [manualTitle, setManualTitle] = useState("");
    const [manualAuthor, setManualAuthor] = useState("");    
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState("")

    const search = () => {
        setLoading(true)
        searchOpenLibrary(query).then((result) => {
            console.log(result);
            setSuggestions(result.docs.slice(0, searchResultLimit).map((doc) => {
                // convert to arrays
                let author = Array.isArray(doc.author_name) ? doc.author_name : [doc.author_name || ""]
                let year = Array.isArray(doc.publish_year) ? doc.publish_year : [doc.publish_year || ""]
                let isbn = Array.isArray(doc.isbn) ? doc.isbn : [doc.isbn || ""]
                return {
                    key: doc.key,
                    title: doc.title,
                    authors: author,
                    publish_years: year,
                    isbns: isbn
                }
            }));
            setLoading(false);
        })
    }

    const queryKeyPress = (e) => {
        if(e.keyCode == 13){
            search()
        }
     }

    const selectManually = () => {
        selectBook({
            title: manualTitle,
            authors: [manualAuthor]
        })
    }

    const selectBook = (book) => {
        setSelectedBook(book)
        onBookSelected(book)
        if (clearResultsOnSelect) {
            setSuggestions([])
            setQuery("")
        }
    }

    let suggestionList
    if (suggestions.length > 0) {
        suggestionList = (
            <List dense>
                { suggestions.map((sugg) => {
                    let secondary = `${sugg.authors.join(", ")} (${sugg.publish_years.join(", ")})`
                    let selected = selectedBook != null && selectedBook.key === sugg.key
                    let icon = selected ? <Check /> : null
                    return (
                        <ListItem key={sugg.key}>
                            <ListItemButton selected={selected} onClick={() => selectBook(sugg)}>
                                <ListItemIcon>{ icon }</ListItemIcon>
                                <ListItemText primary={sugg.title} secondary={secondary} />
                            </ListItemButton>
                        </ListItem>
                    )
                })}
            </List>
        )
    }
    const canSearch = query.length > 0
    const canSelectManually = manualTitle.length > 0
    
    if (selectionType == "search") {
        return (
            <div>
                <TextField 
                    value={query} 
                    autoComplete='off'
                    onChange={(e) => setQuery(e.target.value)}
                    fullWidth
                    margin="dense"
                    onKeyDown={queryKeyPress}
                    placeholder="Enter book title, isbn, or author..." />
                <Button variant="outlined" onClick={search} disabled={loading || !canSearch}>{ loading ? "Searching..." : "Search" }</Button>
                <Button onClick={() => setSelectionType("manual")}>Or, enter book details manually</Button>

                {suggestionList}
                
            </div>
        )
    } else if (selectionType == "manual") {
        return (
            <div>
                <TextField 
                    value={manualTitle} 
                    onChange={(e) => setManualTitle(e.target.value)}
                    fullWidth
                    autoComplete='off'
                    margin="dense"
                    onKeyDown={queryKeyPress}
                    label="Book title"
                    placeholder="Enter book title" />
                <TextField 
                    value={manualAuthor} 
                    onChange={(e) => setManualAuthor(e.target.value)}
                    fullWidth
                    autoComplete='off'
                    margin="dense"
                    onKeyDown={queryKeyPress}
                    label="Book author(s)"
                    placeholder="Enter book author(s) (optional)" />
                <Button 
                    onClick={selectManually} 
                    variant="outlined" 
                    style={{marginTop: 10}}
                    disabled={!canSelectManually}>Confirm</Button>
                <Button onClick={() => setSelectionType("search")}>Or, search for a book</Button>
            </div>
        )
    }
}

BookSelector.defaultProps = {
    searchResultLimit: 30
}