import { getFunctions, httpsCallable } from 'firebase/functions';


const functions = getFunctions();

export const doDeleteGroup = (groupId) => {
    return new Promise((resolve, reject) => {
        const deleteGroupCompletely = httpsCallable(functions, 'deleteGroupCompletely');
        deleteGroupCompletely({ groupId: groupId })
            .then((result) => {
                resolve(result);
            });            
    })
}

// export const doSendActivityNotification = (originatingUserId, groupId, notifTitle, notifBody, notifActionTitle, notifUrl) => {
//     return new Promise((resolve, reject) => {
//         const sendActivityNotification = httpsCallable(functions, 'sendActivityNotification');
//         const data = {
//             type: "group_activity",
//             originatingUserId: originatingUserId,
//             groupId: groupId,
//             notifTitle: notifTitle,
//             notifBody: notifBody,
//             notifActionTitle: notifActionTitle,
//             notifUrl: notifUrl,
//         };
//         sendActivityNotification(data)
//             .then((result) => {
//                 resolve(result);
//             });            
//     })
// }

export const emailAdmins = (subject, message) => {
    return new Promise((resolve, reject) => {
        const doSendEmailToAdmin = httpsCallable(functions, 'doSendEmailToAdmin');
        doSendEmailToAdmin({ subject, message })
            .then((result) => {
                resolve(result);
            });            
    })
}
