import app from '../config/firebase';
import { collection, query, orderBy, limit, getFirestore, onSnapshot, updateDoc, doc, addDoc, where, getDoc, deleteDoc, setDoc } from 'firebase/firestore';  
import util from './util';
import AppConstants from '../constants/AppConstants';
import {union} from 'lodash';
import { cacheUserLookup, getUserLookup } from './ls_helper';
import { parseNote } from './parsing';

/* Reading groups */
const COLL_GROUP = "readinggroups";
const COLL_POLL = "polloptions";
const COLL_NOTES = "notes";

/* Users */
const COLL_USER_PUB = "user_public_profiles";
const COLL_USER_ACC = "user_accounts";
const COLL_USER_PREF = "user_preferences";


/* Users */

async function updateUserPreferences(uid, data) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_USER_PREF, uid)
    return await setDoc(ref, data, {merge: true});
}

async function getUserProfile(uid) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_USER_PUB, uid)
    return await getDoc(ref);
}

async function getUserAccount(uid) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_USER_ACC, uid)
    return await getDoc(ref);
}

async function updateUserProfile(uid, data) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_USER_PUB, uid)
    return await setDoc(ref, data, {merge: true});
}

/* Groups */

const createGroup = (user, type, book) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = collection(db, COLL_GROUP);
        const data = {
            tsCreated: util.nowTimestamp(),
            creatorUserId: user.uid,
            userIds: [user.uid],
            type: type,
            status: 1,
            book: book == null ? null : util.removeUndefineds(book),
            configuration: {
                sectionLabel: "Chapter",
                nSections: 1,
                userLimit: AppConstants.DEFAULT_GROUP_USER_LIMIT
            },
            sectionIdsWithNotes: [],
            userProgress: {}
        }
        addDoc(ref, data).then((doc) => {
            resolve(doc);
        });
    });    
}

const streamGroups = (user, handleSnapshot, handleError) => {
    const db = getFirestore(app);
    const colRef = collection(db, COLL_GROUP)
    const q = query(colRef, orderBy('tsCreated'), where("userIds", "array-contains", user.uid))
    return onSnapshot(q, handleSnapshot, handleError);
}

const listenToGroup = (groupId, handleSnapshot, handleError) => {
    const db = getFirestore(app);
    return onSnapshot(doc(db, COLL_GROUP, groupId), handleSnapshot, handleError);
}

async function getGroup(groupId) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_GROUP, groupId)
    return await getDoc(ref);
}

async function updateGroup(groupId, data) {
    const db = getFirestore(app);
    const ref = doc(db, COLL_GROUP, groupId)
    return await updateDoc(ref, data);
}

async function markLatestActivityForGroup(groupId) {
    // TODO: timestamp in group/{doc} object
    // Then, we'll query on this in a cron function to send notifications
    return updateGroup(groupId, {
        tsLastActivity: util.nowTimestamp()
    });
}



/* Notes */

const createNote = (user, groupId, sectionId, text, replyToNoteId) => {
    const db = getFirestore(app);
    let pages = parseNote(text);
    console.log(text);
    const lowestPage = pages.length > 0 ? Math.min(...pages) : 0;
    return new Promise((resolve, reject) => {
        const ref = collection(db, COLL_GROUP, groupId, 'notes');
        const ts = util.nowTimestamp();
        let sortKey = replyToNoteId == null ? `${lowestPage}_${ts}` : `${ts}`;
        const data = {
            tsCreated: ts,
            userId: user.uid,
            section: sectionId,
            pageRef: lowestPage,
            replyToNoteId: replyToNoteId,
            sortKey: sortKey,
            text: text
        }
        addDoc(ref, data).then(() => {
            resolve(data);
        });
    });    
}

const deleteNote = (groupId, noteId) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = doc(db, COLL_GROUP, groupId, COLL_NOTES, noteId);
        deleteDoc(ref).then(() => {
            resolve();
        });
    });    
}


const streamNotes = (groupId, sectionId, handleSnapshot, handleError) => {
    const db = getFirestore(app);
    const colRef = collection(db, COLL_GROUP, groupId, 'notes')
    const q = query(colRef, where("section", "==", sectionId), orderBy('sortKey'), limit(200))
    return onSnapshot(q, handleSnapshot, handleError);
}

/* Chat messages */

const sendMessage = (user, groupId, text) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = collection(db, COLL_GROUP, groupId, 'messages');
        const data = {
            tsSent: util.nowTimestamp(),
            userId: user.uid,
            text: text
        }
        addDoc(ref, data).then(() => {
            resolve(data);
        });
    });    
}


const streamMessages = (groupId, handleSnapshot, handleError) => {
    const db = getFirestore(app);
    const colRef = collection(db, COLL_GROUP, groupId, 'messages')
    const q = query(colRef, orderBy('tsSent', 'desc'), limit(100))
    return onSnapshot(q, handleSnapshot, handleError);
}

/* Poll options */

const pollSuggestOption = (user, groupId, bookObj) => {
    // Add a book suggestion to a poll
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = collection(db, COLL_GROUP, groupId, COLL_POLL);
        const data = {
            tsSuggested: util.nowTimestamp(),
            suggestingUserId: user.uid,
            voteUserIds: [user.uid], // Auto vote
            nVotes: 0,
            book: bookObj
        };
        addDoc(ref, data).then(() => {
            resolve(data);
        });
    });    
}

const pollGetOption = (groupId, optionId) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        const ref = doc(db, COLL_GROUP, groupId, COLL_POLL, optionId);
        getDoc(ref).then((doc) => {
            resolve(doc)
        });
    });    
}

const pollVoteUpOption = (user, groupId, optionId) => {
    const db = getFirestore(app);
    return new Promise((resolve, reject) => {
        console.log([groupId, optionId])
        const ref = doc(db, COLL_GROUP, groupId, COLL_POLL, optionId);
        getDoc(ref).then((optionSnap) => {
            if (optionSnap.exists()) {
                console.log(optionSnap.data())
                let voteUserIds = optionSnap.data().voteUserIds;
                if (voteUserIds.indexOf(user.uid) === -1) {
                    // Not voted yet, add user
                    voteUserIds.push(user.uid)
                    updateDoc(ref, {
                        voteUserIds
                    }).then(() => {
                        resolve({success: true})
                    })
                } else {
                    resolve({success: false, message: "Already voted"})
                }
            } else {
                console.log("Option doesnt exist")
            }
        });
    });    
}


const streamPollOptions = (groupId, handleSnapshot, handleError) => {
    const db = getFirestore(app);
    const colRef = collection(db, COLL_GROUP, groupId, COLL_POLL)
    const q = query(colRef, orderBy('tsSuggested'))
    return onSnapshot(q, handleSnapshot, handleError);
}

/* Users */

const populateUserCacheIfMissing = (user_ids, force_refresh_ids=[], setUserLookup) => {
    // For each user_id, check if we have info on the user in localstorage
    // If not, fetch and populate from firestore
    const db = getFirestore(app);
    let missing_ids = []
    let lookup = getUserLookup();
    if (lookup == null) {
        lookup = {};
        missing_ids = user_ids;
    } else {
        user_ids.forEach((user_id) => {
            let isMissing = lookup[user_id] == null
            if (isMissing) missing_ids.push(user_id)
        })
    }
    if (force_refresh_ids.length > 0) missing_ids = union(missing_ids, force_refresh_ids)
    if (missing_ids.length > 0) {
        console.log(`Missing ${missing_ids.length} IDs, get from Firestore`);
        const refs = missing_ids.map((user_id) => getDoc(doc(db, COLL_USER_PUB, user_id)))
        Promise.all(refs).then(docs => {
            let changes = false;
            docs.forEach((doc) => {
                if (doc.exists()) {
                    lookup[doc.id] = doc.data()
                    changes = true;
                }
            })
            console.log(`Setting user lookup in ls with ${Object.keys(lookup).length} users`);
            cacheUserLookup(lookup)
            if (changes) {
                setUserLookup({...lookup});
            }
        });
    }
}


export {
    createGroup,
    getGroup,
    deleteNote,
    updateGroup,
    markLatestActivityForGroup,
    streamGroups,
    listenToGroup,
    createNote,
    streamNotes,
    sendMessage,
    streamMessages,
    pollSuggestOption,
    streamPollOptions,
    pollVoteUpOption,
    pollGetOption,
    populateUserCacheIfMissing,
    getUserProfile,
    getUserAccount,
    updateUserPreferences,
    updateUserProfile
}