// Routes
import NotFound from '../components/NotFound'
import GroupView, { loader as groupLoader } from '../views/GroupView'
import Login from '../views/Login'
import Join from '../views/Join'

import Home from '../views/Home'
import Landing from '../views/Landing'
import Profile from '../views/Profile'
import Privacy from '../views/Privacy'

import App from '../App';
import { Navigate } from 'react-router-dom'
import About from '../views/AboutView'

export const routes = [
  {
    path: "/",
    element: <App />,
    errorElement: <NotFound />,
    children: [
      {
        path: '',
        element: <Navigate to="/landing" />,
      },
      {
        path: "login",
        index: true,
        element: <Login />,
      },
      {
        path: "group/:groupId",
        element: <GroupView />,
        // loader: groupLoader
      },
      {
        path: "groups/join/:inviteCode",
        element: <Join />,
      },
      {
        path: "app",
        element: <Home />,
      },
      {
        path: "about",
        element: <About />,
      },      
      {
        path: "privacy",
        element: <Privacy />,
      },      
      {
        path: "profile",
        element: <Profile />
      },
    ]
  },
  {
    path: "landing",
    element: <Landing />
  }
]