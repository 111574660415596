import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Tabs, Tab, Divider } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { Link, useNavigate } from 'react-router-dom';
import LoginForm from '../components/LoginForm';
import AppConstants from '../constants/AppConstants';


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
}

export default function About({ theme }) {
    const navigate = useNavigate();
    const [tab, setTab] = useState(0);

    const handleTabChange = (e, newTab) => {
        setTab(newTab)
    }

    return (
        <Container maxWidth="md">

            <Typography variant="h3">About</Typography>

            <Box sx={{ width: '100%' }}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={tab} onChange={handleTabChange} centered>
                        <Tab label="What is this?"  />
                        <Tab label="Pricing" />
                        <Tab label="Open Data" />
                    </Tabs>
                </Box>
                <CustomTabPanel value={tab} index={0}>
                    {AppConstants.SITENAME} is an asynchronous book club for you and the people you like to share books with. Pick a book, invite friends to a private group, then get started reading and sharing notes.
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={1}>
                    We aren't interested in affiliate links, paid promotions, or other revenue models. 
                    Using {AppConstants.SITENAME} is free for use by small groups. 
                    You can support development costs by subscribing to the standard plan.
                    
                    <Divider style={{margin: 20}} />
                    <Typography variant="h5">Free Plan</Typography>
                    <ul>
                        <li>Create up to 1 group per month</li>
                        <li>Groups can have up to 3 members</li>
                    </ul>

                    <Typography variant="h5">Standard Plan ($3/month)</Typography>
                    <ul>
                        <li>Unlimited group creation</li>
                        <li>Groups can have up to 10 members</li>
                    </ul>
                </CustomTabPanel>
                <CustomTabPanel value={tab} index={2}>
                    100% of data you and your groups share on {AppConstants.SITENAME} is owned by you. It will never be shared with any third party, in any case. 
                    You can permanently delete your data at any time, and data is designed to be maximally portable. Collective notes for each book can be exported by any group member as a single Markdown file.
                    <br/><br/>
                    <Typography variant="body1">See the <Link to="/privacy">privacy policy</Link> for details.</Typography>

                </CustomTabPanel>
            </Box>
                
            
            

        </Container>
    );
}
