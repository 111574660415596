import React, { useContext, useEffect, useState } from 'react';
import SectionNav from '../components/SectionNav';
import ParticipantList from '../components/ParticipantList';
import ParticipantInviter from '../components/ParticipantInviter';
import SectionNotes from '../components/SectionNotes'
import GroupChat from '../components/GroupChat'
import { Box, Container, Grid, Paper, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { listenToGroup, populateUserCacheIfMissing } from '../utils/db_helper';
import BookPoll from '../components/BookPoll';
import styled from '@emotion/styled';
import { useSnackbar } from 'notistack';
import { BookInfoHeader } from '../components/BookInfoHeader';
import { AppContext } from '../config/AppContext';

const ColumnContent = styled(Box)(({ theme, left }) => ({
    ...theme.typography.body2,
    padding: 20,
    minHeight: 1000,
    textAlign: 'center',
}));

export default function GroupView() {
    let { groupId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const [group, setGroup] = useState(null);
    let defaultSection = parseInt(searchParams.get("section", 1));
    if (isNaN(defaultSection)) defaultSection = 1;
    const [activeSection, setActiveSection] = useState(defaultSection)
    const { enqueueSnackbar } = useSnackbar()
    const {setUserLookup} = useContext(AppContext);
    const isWide = useMediaQuery('(min-width:600px)');

    useEffect(() => {
        const unsubscribe = listenToGroup(groupId,
            (doc) => {
                populateUserCacheIfMissing(doc.data().userIds, [], setUserLookup)
                setGroup({
                    ...doc.data(),
                    id: doc.id
                });    
            },
            (error) => {
                console.log(error)
                enqueueSnackbar("Error getting group")
            }
        );
        return unsubscribe;
    }, [groupId])

    if (group == null) return;

    const bookChosen = group.book != null;
    const config = group.configuration;
    let bookInfo, notesView;
    if (bookChosen) {
        bookInfo = <BookInfoHeader group={group} />
        notesView = (
            <div>
                <Toolbar align="left" disableGutters>
                    <Typography variant="h6" sx={{ flexGrow: 1 }} gutterBottom>{`${config.sectionLabel} ${activeSection} Notes`}</Typography>
                    <SectionNav nSections={group.configuration.nSections} 
                            active={activeSection} 
                            groupId={group.id}
                            sectionLabel={group.configuration.sectionLabel}
                            sectionsWithNotes={group.sectionIdsWithNotes}
                            onChangeSection={(sec) => setActiveSection(sec)} />
                </Toolbar>
                
                <SectionNotes group={group} sectionLabel={group.configuration.sectionLabel} sectionId={activeSection} />

            </div>
        )

    } else {
        notesView = (
            <BookPoll groupId={groupId} />
        )
    }

    // TODO: DRY

    const fullLayout = (
        <div>
            <Grid container spacing={1}>
                <Grid item xs={4}>
                    <ColumnContent left>
                        { bookInfo }
                        <ParticipantList 
                            user_ids={group.userIds} 
                            nSections={group.configuration.nSections} 
                            userProgress={group.userProgress || {}} />
                        <ParticipantInviter groupId={groupId} user_ids={group.userIds || []} userLimit={group.configuration.userLimit} />
                        <GroupChat groupId={group.id} />
                    </ColumnContent>
                </Grid>

                <Grid item xs={8}>
                    <ColumnContent>
                        <Paper style={{padding: 15, marginBottom: 15}}>
                            { notesView }
                        </Paper>
                    </ColumnContent>
                </Grid>
            </Grid>
        </div>
    )

    const mobileLayout = (
        <Container maxWidth="md" style={{paddingTop: 20}}>
            { bookInfo }
            <ParticipantList 
                user_ids={group.userIds} 
                nSections={group.configuration.nSections} 
                userProgress={group.userProgress || {}} />
            <ParticipantInviter groupId={groupId} user_ids={group.userIds} userLimit={group.configuration.userLimit} />

            <Paper style={{padding: 15, marginBottom: 15, marginTop: 15}}>
                { notesView }
            </Paper>

            <GroupChat groupId={group.id} startMinimized />

        </Container>
    )

    return isWide ? fullLayout : mobileLayout;
}

const styles = {
    leftColumn: {
        minHeight: 800
    }
}