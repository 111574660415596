import { createTheme } from "@mui/material";
import AppConstants from "../constants/AppConstants";

export const muiTheme = createTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      marginBottom: 20,
      marginTop: 20,
    }
  },
  palette: {
    primary: {
      main: AppConstants.PRIMARY_COLOR
    },
    danger: {
      main: "rgb(255, 0, 0)"
    }
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#EFEFEF",
        }
      }
    }
  }
});
