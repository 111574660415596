import { Avatar } from '@mui/material';
import React, { useContext } from 'react';
import util from '../utils/util';
import { AppContext } from '../config/AppContext';
import { Person } from '@mui/icons-material';


export default function UserAvatar({ user_id, size, align }) {
    const userLookup = useContext(AppContext).userLookup || {};
    const isSmall = size < 34;
    const fontSize = isSmall ? 10 : 16
    const sx = {
        bgcolor: util.stringToColor(user_id),
        height: size,
        width: size,
        align: align,
        fontSize: fontSize
    }

    const unknownName = userLookup[user_id] == null
    const displayName = !unknownName ? (userLookup[user_id].displayName || "?") : "?";
    const title = displayName === "?" ? user_id : displayName;
    const personIconSize = isSmall ? "12px" : "20px"
    const avatarContent = unknownName ? <Person fontSize={personIconSize} /> : displayName[0].toUpperCase()
    return (
        <Avatar sx={sx} alt={displayName} title={title} key={user_id}>
            { avatarContent }
        </Avatar>
    )
}

UserAvatar.defaultProps = {
    user_id: null,
    size: 34,
    align: 'left'
}