import { Card, CardActions, CardContent, IconButton, Typography } from "@mui/material"
import UserAvatar from "./UserAvatar"
import DateStamp from "./DateStamp"
import { Delete, Reply } from "@mui/icons-material"
import { deleteNote } from "../utils/db_helper"
import HighlightedText from "./HighlightedText"



export default function SectionNote({groupId, note, isMine, onClickReply}) {
    let actions = []
    const isReply = note.replyToNoteId != null;
    if (isMine) actions = [
        <IconButton key="delete" onClick={() => doDeleteNote(note.id)}>
            <Delete />
        </IconButton>
    ]

    if (!isReply) actions.push((
        <IconButton key="reply" onClick={onClickReply}>
            <Reply />
        </IconButton>
    ))

    const doDeleteNote = (noteId) => {
        deleteNote(groupId, noteId).then(() => {
            console.log(`Deleted note ${noteId}`);
        })
    }

    return (
        <Card key={note.id} style={isReply ? styles.replyNote : styles.note} variant="outlined">
            <CardContent>
                <Typography align="left" style={{whiteSpace: 'pre-line'}}>{ note.text }</Typography>        
            </CardContent>
            <CardActions>
                <div>
                    { actions }
                </div>
                <UserAvatar user_id={note.userId} size={23} />
                <DateStamp ts={note.tsCreated} />
            </CardActions>
        </Card>
    )
}


const styles = {
    note: {
        marginBottom: '10px'
    },
    replyNote: {
        backgroundColor: "#EFEFEF",
        marginLeft: "40px",
        marginBottom: '10px'
    }
}