import React, { useEffect, useState } from 'react';
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Typography, Container} from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import app from '../config/firebase';
import { useSnackbar } from 'notistack';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useNavigate, useParams } from 'react-router-dom';
import { LogoutSharp } from '@mui/icons-material';
import LoginForm from '../components/LoginForm';

const auth = getAuth(app);
const functions = getFunctions(app);

export default function Join() {
    const [user, loadingUser, error] = useAuthState(auth);
    const params = useParams()
    const {inviteCode} = params;
    const [open, setOpen] = useState(false)
    const [result, setResult] = useState(null)
    const [loading, setLoading] = useState(false)
    const [codeText, setCodeText] = useState(params.inviteCode)
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const navigate = useNavigate();

    // TODO: Redirect if not logged in

    const signedIn = user != null;

    useEffect(() => {
        if (user != null) setOpen(true)
    }, [user])

    const gotoLogin = () => {
        navigate(`/login?inviteCode=${inviteCode}`)
    }

    const join = () => {
        setLoading(true)
        const joinGroup = httpsCallable(functions, 'joinGroup');
        joinGroup({ userId: user.uid, invite_code: codeText })
            .then((result) => {
                console.log(result)
                const data = result.data;
                setResult(result.data);
                setLoading(false)
                if (data.success) {
                    navigate(`/group/${data.groupId}`);
                } else {
                    if (data.message != null) enqueueSnackbar(data.message);
                }
            });        
    }

    const dismiss = () => {
        setOpen(false)
    }

    let loggedOutSec
    if (!signedIn) loggedOutSec = (
        <Container maxWidth="md">
            <Typography variant='h2' style={{marginTop: 20}}>You're invited to join a group!</Typography>
            <Typography variant='h5'>To join, please create a new account, or login if you already have one.</Typography>
            <LoginForm redirect={`/groups/join/${inviteCode}`} />
        </Container>
    )
    return (
        <div>
            
            { loggedOutSec }

            <Dialog open={open} onClose={dismiss}>
                <DialogTitle>Join group with invite code</DialogTitle>
                <DialogContent>
                    <TextField value={codeText} onChange={(e) => setCodeText(e.target.value)} fullWidth placeholder="Paste code" />
                </DialogContent>
                <DialogActions>
                    <Button variant="flat" onClick={dismiss}>Dismiss</Button>
                    <Button variant="contained" onClick={join} disabled={loading}>Join</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
