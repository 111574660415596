const AppConstants = {
    YEAR: "2023",
    SITENAME: "Marginalia",	
    SUPPORT_EMAIL: "marginalia.webapp@gmail.com",
    TAGLINE: "Read more, and more deeply, with friends",
    SUBTAGLINE: "Simple, shared book notes for social reading.",
    DOMAIN: "https://marginalia.site",
    LS_KEY_USER_LOOKUP: "bookGroupULookup",
    DEFAULT_GROUP_USER_LIMIT: 3,
    PRIMARY_COLOR: "#635ee7"
};

export default AppConstants