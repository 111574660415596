import { getMessaging, getToken } from "firebase/messaging";
import { getFirestore, doc, setDoc, deleteDoc, arrayUnion } from "firebase/firestore";
import { updateUserAccount, updateUserPreferences } from "./db_helper";

const FCM_VAPID_KEY = "BEvHs2vQ9uc25eccUl2dt-zB8tDY7VTvidjsNO0Y-0oBQE05O-WPlQY8jka8xYUAhiOt9g4kY90ZTB9LcYVq8y8";

const messaging = getMessaging();

export const getFCMPermissionThenToken = (user, callback) => {
  Notification.requestPermission().then((permission) => {
    if (permission === 'granted') {
      console.log('Notification permission granted.');
      getAndStoreFCMToken();
    }
  });
}

export const getAndStoreFCMToken = (user, callback) => {
  console.log("getAndStoreFCMToken...");
  getToken(messaging, {vapidKey: FCM_VAPID_KEY}).then((currentToken) => {
    if (currentToken) {
      // Send the token to your server and update the UI if necessary
      console.log("Have token... " + currentToken);
      updateUserPreferences(user.uid, {
        fcmTokens: arrayUnion(currentToken)
      }).then(() => {
        callback();
      });
    } else {
      // Show permission request UI
      console.log('No registration token available. Request permission to generate one.');
      getFCMPermissionThenToken(user, callback);
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
  });
}