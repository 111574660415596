import React, { useEffect, useState } from 'react';
import { TextField, Button, Container, Typography, Box, Tabs, Tab, Divider } from '@mui/material';
import { Link } from 'react-router-dom';
import AppConstants from '../constants/AppConstants';


export default function Privacy({ theme }) {

    return (
        <Container maxWidth="md">

            <Typography variant="h3">Privacy Policy</Typography>

            Effective Date: 2023-08-25
            <br/>
            <br/>

            This Privacy Policy outlines how Marginalia ("we," "us," or "our") collects, uses, and protects the personal information you ("user" or "you") provide while using our application ("the app").
            <br/>

            <Typography variant="h4" style={styles.header}>Information We Collect</Typography>

            We may collect the following types of information when you use our app:
            <br/>

            User-Provided Information: We collect the information you provide when using our app, which may include but is not limited to username, email address, book notes, etc.
            <br/>

            Automatically Collected Information: We may collect certain information automatically, e.g. date of last activity, etc.
            <br/>

            <Typography variant="h4" style={styles.header}>Ownership of Data</Typography>

            All data and information shared or provided by you within the app remain your sole property. We do not claim ownership of any user-generated content or personal data that you provide while using the app.
            <br/>

            <Typography variant="h4" style={styles.header}>Use of Your Data</Typography>

            We only use the data you provide for the purpose of enhancing your app experience. Your data will never be shared with any third party for marketing, advertising, or any other purposes.
            <br/>

            <Typography variant="h4" style={styles.header}>Data Security</Typography>

            We are committed to ensuring the security of your data. We implement reasonable technical and organizational measures to protect your information from unauthorized access, loss, or misuse.
            <br/>

            <Typography variant="h4" style={styles.header}>Third-Party Services</Typography>

            Our app does not integrate with any third-party services.
            <br/>

            <Typography variant="h4" style={styles.header}>Children's Privacy</Typography>

            Our app is not intended for use by individuals under the age of 13 (or the equivalent minimum age in your jurisdiction). We do not knowingly collect personal information from children under 13. If you are a parent or guardian and believe that your child has provided us with personal information, please contact us so that we can delete the information.
            <br/>

            <Typography variant="h4" style={styles.header}>Changes to this Policy</Typography>

            We may update this Privacy Policy from time to time, and any changes will be reflected in the updated policy with a new effective date. We encourage you to review this policy periodically.
            <br/>

            <Typography variant="h4" style={styles.header}>Contact Us</Typography>

            If you have any questions, concerns, or requests related to your data or this Privacy Policy, you can contact us at <Link to={`mailto:${AppConstants.SUPPORT_EMAIL}`}>{AppConstants.SUPPORT_EMAIL}</Link>.
            <br/>

            By using our app, you agree to the terms outlined in this Privacy Policy.
            <br/>
        </Container>
    );
}

const styles = {
    header: {
        marginTop: '20px',
        marginBottom: '20px',
    }
}