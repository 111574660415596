
export const pageRangesRegex = /(?:page|pages|pg|pgs)\s?(\d+)-?(\d+)?/gm;

export const parseNote = (text) => {
    let m;
    let pages = [];
    while ((m = pageRangesRegex.exec(text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === pageRangesRegex.lastIndex) {
            pageRangesRegex.lastIndex++;
        }
        
        // The result can be accessed through the `m`-variable.
        m.forEach((match, groupIndex) => {
            const parsedValue = parseInt(match);
            if (!isNaN(parsedValue)) {
                console.log(`Found match, group ${groupIndex}: ${match}`);
                pages.push(parsedValue);
            }
        });
    }
    console.log(`Parsed pages: ${pages}`);
    return pages;
}
