import { ContentCopy } from '@mui/icons-material';
import { Button, Snackbar } from '@mui/material'
import { useSnackbar } from 'notistack';
import { useState } from 'react'

const CopyToClipboardButton = ({ value }) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const handleClick = () => {
      navigator.clipboard.writeText(value)
      enqueueSnackbar("Link copied to clipboard")
    }
    
    return (
        <>
          <Button onClick={handleClick} startIcon={<ContentCopy />}>Copy</Button>
        </>
    )
}

export default CopyToClipboardButton