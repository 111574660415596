import React, { useEffect, useState } from 'react';
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Container, Select, MenuItem, Typography, Fab} from '@mui/material';

import { createNote, pollSuggestOption, pollVoteUpOption, streamNotes, streamPollOptions } from '../utils/db_helper';
import { Link, NavLink, Outlet, useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth } from 'firebase/auth';

import app from '../config/firebase';
import { Add, Check, ThumbUp, Update } from '@mui/icons-material';
import BookOptionMenu from './BookOptionMenu';
import BookSelector from './BookSelector';
const auth = getAuth(app);

export default function BookPoll({ groupId }) {
    const [pollOptions, setPollOptions] = useState([]);
    const [suggestionOpen, setSuggestionOpen] = useState(false);

    const [user, loading, error] = useAuthState(auth);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    
    const suggestOption = (book) => {
        pollSuggestOption(user, groupId, book).then((group) => {
            console.log(group)
            enqueueSnackbar("Suggestion added");
        })
    }

    useEffect(() => {
        const unsubscribe = streamPollOptions(groupId,
            (querySnapshot) => {
                const items = 
                querySnapshot.docs.map(docSnapshot => {
                    return {
                        ...docSnapshot.data(),
                        id: docSnapshot.id
                    }
                });
                setPollOptions(items);
            },
            (error) => {
                console.log(error)
                enqueueSnackbar('Failure getting items')
            }
        );
        return unsubscribe;
    }, [groupId]);    


    return (
        <div className="Notes">
            <Typography variant="h4">What should we read?</Typography>
            <Typography>Vote on books others have suggested, or suggest your own. You can vote for multiple books.</Typography>
            <List>
                { pollOptions.map((op) => {
                    let nVotes = `${op.voteUserIds.length} vote(s)`;
                    const voted = op.voteUserIds.indexOf(user.uid) > -1
                    const canVote = !voted                
                    let action = <BookOptionMenu option={op} user={user} canVote={canVote} groupId={groupId} />
                    let icon
                    if (voted) icon = <ListItemIcon><ThumbUp /></ListItemIcon>
                    return (
                    <ListItem key={op.id} secondaryAction={action}>
                        { icon }
                        <ListItemText primary={op.book.title} secondary={nVotes}></ListItemText>
                    </ListItem>
                    )
                })}
            </List>
            
            <div hidden={suggestionOpen}>
                <Fab variant="extended" style={styles.fab} onClick={() => setSuggestionOpen(true)} color="primary" >
                    <Add sx={{ mr: 1 }} />
                    Suggest a book
                </Fab>                    
            </div>
 
            <div hidden={!suggestionOpen}>
                <h4>Suggest a book</h4>
                <BookSelector 
                    onBookSelected={(book) => suggestOption(book)}
                    clearResultsOnSelect />
            </div>


        </div>
    )
}


const styles = {
    fab: {
        align: "right",
        marginLeft: "auto",
        marginTop: 10
    }
}