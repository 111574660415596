import React, { useEffect, useState } from 'react';
import {Paper, List, ListItem, Dialog, IconButton, TextField,
    Button, Toggle, Switch, ListItemButton, ListItemIcon, ListItemText, DialogTitle, DialogContent, DialogActions, FormControlLabel, Container, ListItemSecondaryAction, capitalize, Typography} from '@mui/material';
import { getAuth } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';

import app from '../config/firebase';
import { getUserAccount, getUserProfile, updateUserProfile } from '../utils/db_helper';
import { Edit } from '@mui/icons-material';
import { editableInputTypes } from '@testing-library/user-event/dist/utils';
import { updateInUserCache } from '../utils/ls_helper';
import { emailAdmins } from '../utils/function_helper';
import { enqueueSnackbar } from 'notistack';

const auth = getAuth(app);



export default function Profile() {
    const [editingAttribute, setEditingAttribute] = useState(null)
    const [open, setOpen] = useState(false)
    const [dialogTitle, setDialogTitle] = useState(null)
    const [upgradeDialogShowing, setUpgradeDialogShowing] = useState(false);
    const [user, loading, error] = useAuthState(auth);    
    const [inputValue, setInputValue] = useState("");
    const [userProfile, setUserProfile] = useState({})
    const [userAccount, setUserAccount] = useState({})

    
    useEffect(() => {
        if (user != null) {
            getUserProfile(user.uid).then((result) => {
                setUserProfile(result.data() || {});
            })    
            getUserAccount(user.uid).then((result) => {
                setUserAccount(result.data() || {});
            })    
        }
    }, [user]);

    const saveProfile = () => {
        let updates = {}
        updates[editingAttribute] = inputValue;
        updateUserProfile(user.uid, updates).then((result) => {
            // TODO: Hackish
            userProfile[editingAttribute] = inputValue
            setUserProfile({...userProfile})
            if (editingAttribute === "displayName") {
                updateInUserCache(user.uid, inputValue);
            }
            dismiss()
        });
    }

    if (user == null) return;

    const handleChange = (value) => {
        setInputValue(value)
    }

    const openEditor = (attr, label) => {
        setOpen(true)
        setEditingAttribute(attr)
        setInputValue(userProfile[attr])
        setDialogTitle(`Edit ${label}`);
    }

    const dismiss = () => {
        setOpen(false);
        setEditingAttribute(null);
        setInputValue("");
    }

    const dismissUpgradeDialog = () => {
        setUpgradeDialogShowing(false);
    }

    const validateInput = () => {
        let validated = false;
        let validationResult = "";
        if (editingAttribute === "displayName") {
            validated = /^[a-zA-Z0-9]+$/.test(inputValue);
            if (!validated) validationResult = "Display name should contain only letters and numbers"
        }
        return {validated, validationResult}
    }

    const requestUpgrade = () => {
        // Send email
        emailAdmins("Upgrade request", `From user ${user.uid} - ${user.email}`).then(() => {
            enqueueSnackbar("Request sent, thank you!");
            dismissUpgradeDialog();
            window.gtag('event', 'conversion', {'send_to': 'AW-11082207942/85OVCLqX3dcYEMaltKQp'});
        })
    }

    const {validated, validationResult} = validateInput()

    return (
        <Container maxWidth="md">
            <h2>Profile</h2>

            <Paper>
                <List>
                    <ListItem>
                        <ListItemText primary={user.email} secondary="Email" />
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={userProfile.displayName || "--"} secondary="Display Name (visible only to your groups)" />
                        <ListItemSecondaryAction>
                            <IconButton onClick={() => openEditor('displayName', "Display Name")}>
                                <Edit />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem>
                        <ListItemText primary={userAccount.plan != null ? capitalize(userAccount.plan) : "Free"} secondary="Plan" />
                        <ListItemSecondaryAction>
                            <Button onClick={() => setUpgradeDialogShowing(true)}>Request Upgrade</Button>
                        </ListItemSecondaryAction>
                    </ListItem>                    
                </List>
            </Paper>

            <Dialog open={open} onClose={dismiss} fullWidth maxWidth="xs">
                <DialogTitle>{ dialogTitle }</DialogTitle>
                <DialogContent>
                    <TextField error={!validated} helperText={validationResult} value={inputValue || ""} onChange={(e) => handleChange(e.target.value)}></TextField>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dismiss}>Cancel</Button>
                    <Button onClick={saveProfile} variant="outlined">Update</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={upgradeDialogShowing} onClose={dismissUpgradeDialog} fullWidth maxWidth="xs">
                <DialogTitle>{ dialogTitle }</DialogTitle>
                <DialogContent>
                    <Typography>To request an upgrade to the standard plan, click below.</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={dismissUpgradeDialog}>Cancel</Button>
                    <Button onClick={requestUpgrade} variant="outlined">Request Upgrade</Button>
                </DialogActions>
            </Dialog>

        </Container>
    )
}
