import Footer from './components/Footer'
import TopBar from './components/TopBar'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Outlet } from "react-router-dom";
import { SnackbarProvider } from 'notistack'
import { AppContext } from './config/AppContext';
import AppConstants from './constants/AppConstants';
import { getUserLookup } from './utils/ls_helper';

import './App.css'
import { useState } from 'react';
import { muiTheme } from './config/theme';


export default function App() {
  const [userLookup, setUserLookup] = useState(getUserLookup());

  return (
        <ThemeProvider theme={muiTheme}>
          <AppContext.Provider
            value={{ userLookup, setUserLookup }}
          >
            <SnackbarProvider>
                <TopBar />
                <div style={{minHeight: 800}}>
                  <Outlet />
                </div>
                <Footer />
            </SnackbarProvider>
          </AppContext.Provider>
        </ThemeProvider>
    )
}
