import { Note } from '@mui/icons-material';
import { createNote, markLatestActivityForGroup, streamNotes, updateGroup } from '../utils/db_helper';
import { useSnackbar } from 'notistack';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useState } from 'react';
import app from '../config/firebase';
import { getAuth } from 'firebase/auth';
import { Button, TextField } from '@mui/material';

const auth = getAuth(app);

function NoteCreator({ showing, buttonLabel, group, sectionId, replyToNoteId, onNoteCreated, onCreationCancelled }) {
    const [noteText, setNoteText] = useState("")
    const [user, loading, error] = useAuthState(auth);
    const { enqueueSnackbar } = useSnackbar()

    const groupId = group.id
    const isReply = replyToNoteId != null;

    const handleNoteChange = (value) => {
        setNoteText(value)
    }

    const maybeUpdateSectionIdsWithNotes = () => {
        // If added note to a section not already in list `sectionIdsWithNotes`
        // add it.
        if (group.sectionIdsWithNotes == null || group.sectionIdsWithNotes.indexOf(sectionId) === -1) {
            // Add
            const _sectionIdsWithNotes = group.sectionIdsWithNotes || [];
            _sectionIdsWithNotes.push(sectionId);
            updateGroup(groupId, {
                sectionIdsWithNotes: _sectionIdsWithNotes
            });
        }
    }

    const maybeUpdateUserProgress = () => {
        if (group.userProgress == null || group.userProgress[user.uid] == null ||
                group.userProgress[user.uid] < sectionId) {
            // Update with new sectionId
            const userProgress = group.userProgress || {};
            userProgress[user.uid] = sectionId;
            updateGroup(groupId, {
                userProgress: userProgress
            });
        }
    }

    const createNewNote = () => {
        createNote(user, groupId, sectionId, noteText, replyToNoteId).then((note) => {
            console.log(note)
            setNoteText("")
            enqueueSnackbar("Note added");
            markLatestActivityForGroup(groupId).then(() => {
                maybeUpdateSectionIdsWithNotes()
                maybeUpdateUserProgress()
                onNoteCreated();
            });
        })
    }

    const canCreate = noteText.length > 0

    return (
        <div autoComplete="off" hidden={!showing}>
            <TextField 
                value={noteText || ""} 
                onChange={(e) => handleNoteChange(e.target.value)} 
                placeholder={isReply ? "Write a reply..." : "Write a note..."}
                multiline
                margin="dense"
                rows={4}
                fullWidth />
            <Button variant="contained" 
                onClick={createNewNote} 
                sx={styles.button}
                disabled={!canCreate}
                startIcon={<Note />}>{buttonLabel}</Button>
            <Button variant="outlined" 
                onClick={onCreationCancelled} 
                sx={styles.button}
                >Cancel</Button>
        </div>
    )
}

NoteCreator.defaultProps = {
    buttonLabel: "Create Note",
    onNoteCreated: () => {},
    onCreationCancelled: () => {},
    replyToNoteId: null
}

const styles = {
    fab: {
        marginTop: 10,

    },
    button: {
        margin: '10px 10px 10px 0px'
    },
}

export default NoteCreator;