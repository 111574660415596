import React from 'react';
import {Card, CardActions, CardContent, IconButton, Typography} from '@mui/material';
import { Link } from 'react-router-dom';
import ParticipantList from './ParticipantList';
import DateStamp from './DateStamp';
import { Book, Delete, EditNote } from '@mui/icons-material';

export const GroupCard = ({ working, user, group, onDeleteRequest }) => {
    const {book} = group

    const bookSelected = book != null;
    let title = (!bookSelected) ? "New group" : <span><Book fontSize="small" /> {book.title}</span>;
    const canDelete = group.userIds.indexOf(user.uid) > -1;

    const requestCleanDelete = () => {
        onDeleteRequest(group);
    }

    return (
        <Card key={group.id} style={styles.group} variant="outlined">
            <CardContent>
                <Link to={`/group/${group.id}`}><Typography variant="h6">{ title }</Typography></Link>
            </CardContent>
            <CardActions>
                <ParticipantList size={23} user_ids={group.userIds} showProgress={false} />
                <EditNote style={{color: "gray", marginLeft: 20}} />
                <DateStamp ts={group.tsLastActivity || group.tsCreated} withTime={false} />
                { canDelete ? <IconButton disabled={working} onClick={requestCleanDelete}><Delete /></IconButton> : null }
            </CardActions>
        </Card>
    )
}


const styles = {
    group: {
        marginBottom: 2,
    }
}