import { Typography } from "@mui/material";
import util from "../utils/util"


export default function DateStamp({ ts, withTime }) {
    const sx = {
        color: "gray",
        fontSize: 12,
        marginLeft: '10px'
    }
    const timeStr = (ts == null) ? "--" : util.printDate(ts, withTime);
    return <Typography sx={sx}>{ timeStr }</Typography>
}

DateStamp.defaultProps = {
    withTime: true
}