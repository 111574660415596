import React from 'react';
import AppConstants from '../constants/AppConstants'
import { Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

export default function Footer({ theme }) {

  var YEAR = new Date().getFullYear();
  let year_text;
  if (AppConstants.YEAR != YEAR) year_text = <span>&copy; { AppConstants.YEAR } - { YEAR } { AppConstants.SITENAME }</span>
  else year_text = <span>&copy; { YEAR } { AppConstants.SITENAME }</span>
  return (
    <Box style={styles.footer} className="footer">
        <Typography align="center">{ year_text } | <Link to="/privacy">Privacy Policy</Link></Typography>
    </Box>
  )
}


const styles = {
  footer: {
    backgroundColor: "#222",
    color: "#999",
    padding: 20,
    marginTop: 20
  }
}