import React from 'react';
import {Typography} from '@mui/material';

export const BookInfoHeader = ({ group }) => {
    let displayAuthors = Array.isArray(group.book.authors) ? group.book.authors.join(", ") : "-";
    return (
        <div>
            <Typography variant="h4">{group.book.title}</Typography>
            <Typography variant="h5">By {displayAuthors}</Typography>
        </div>
    )
}