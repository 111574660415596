import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyDdZEuneqdJoVVJfVmR_qUAfsWfUg__4cQ",
  authDomain: "book-group-async.firebaseapp.com",
  projectId: "book-group-async",
  storageBucket: "book-group-async.appspot.com",
  messagingSenderId: "877539546254",
  appId: "1:877539546254:web:547779cf7a0753da0465df"
};

const app = initializeApp(firebaseConfig);

export default app;