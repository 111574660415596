import { Button, Container, Grid, ThemeProvider, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer";
import TopBar from "../components/TopBar";
import AppConstants from "../constants/AppConstants";
import { useMediaQuery } from "react-responsive";
import { muiTheme } from "../config/theme";
import { StarBorderOutlined } from "@mui/icons-material";


export default function Landing() {
    const navigate = useNavigate()

    const isSmallScreen = useMediaQuery({
        query: '(max-width: 768px)'
    });

    const subSplashContent = (
        <div className="subSplashContent">
            <Grid container style={styles.main}>
                <Grid item xs={12} sm={6} md={6} align="center" style={styles.screenshot}>
                    <img alt="Screenshot of Marginalia" src="images/MarginaliaScreenshot.png" width={300} />
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <Typography variant="h4">How it works</Typography>
                    <ol style={styles.steps}>
                        <li>Invite your reading group</li>
                        <li>Pick or run a poll to choose your first book</li>
                        <li>Read, note, respond, and share progress</li>
                    </ol>
                </Grid>
            </Grid>
        </div>
    )

    return (
        <ThemeProvider theme={muiTheme}>
            <div id="home">
                <div className="splash">
                    <Container maxWidth="lg">
                        <TopBar transparent disableGutters />
                        <Typography variant="h2" style={styles.tagline}>{ AppConstants.TAGLINE }</Typography>
                        <Typography variant="h3" style={styles.subhead}>{ AppConstants.SUBTAGLINE }</Typography>
                        { !isSmallScreen ? subSplashContent : null }                    
                    </Container>
                </div>
                { isSmallScreen ? subSplashContent : null }
                <div style={styles.belowTheFold}>
                    <Container maxWidth="lg">
                        <Typography variant="h3">Try it out!</Typography>
                        <Typography variant="h5" style={{marginBottom: 30}}>Marginalia is free for small groups, and committed to privacy, and open, portable, data.</Typography>
                        <Button variant="contained" startIcon={<StarBorderOutlined />} size="large" onClick={() => navigate("/login")}>Sign Up</Button>
                    </Container>
                </div>
                <Footer />
            </div>
        </ThemeProvider>
    )
}

const styles = {
    steps: {
        fontSize: '1.7em'
    },
    tagline: {
        fontSize: '4.0em',
        color: 'white',
        maxWidth: "600px"
    },
    subhead: {
        fontSize: '2em',
        maxWidth: "600px"
    },
    screenshot: {
        marginBottom: '20px'
    },
    belowTheFold: {
        margin: '30px'
    },
    main: {
        marginTop: 40
    }
}