import { Avatar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";

import app from '../config/firebase'
import CopyToClipboardButton from './CopyToClipboardButton';
import { InsertLink, PersonAdd } from '@mui/icons-material';
const functions = getFunctions();

export default function ParticipantInviter({ groupId, user_ids, userLimit }) {
    
    const [open, setOpen] = useState(false)
    const [invitation, setInvitation] = useState(null)
    const [creating, setCreating] = useState(false)

    const created = invitation != null

    const create = () => {
        setCreating(true)
        console.log(`groupId: ${groupId}`);
        const createInvite = httpsCallable(functions, 'createInvite');
        createInvite({ groupId: groupId })
            .then((result) => {
                console.log(result)
                setInvitation(result.data);
                setCreating(false)
            });        
    }

    const dismiss = () => {
        setOpen(false)
    }

    let content
    if (invitation != null) {
        content = (
        <div>
            <Typography>Share the link below with anyone you want to invite. It will expire in 48 hours.</Typography>

            <TextField value={invitation.link} fullWidth />
            <CopyToClipboardButton value={invitation.link} />
        </div>
        )
    } else {
        content = (
            <div>
                Create an invite link, which you'll share with people you want to invite.
            </div>
        )
    }

    const canInvite = user_ids.length < userLimit

    return (
        <div>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                >
                <Grid item justifyContent="flex-start" xs={12}>
                    <Button 
                        onClick={() => setOpen(true)} 
                        disabled={!canInvite}
                        startIcon={<PersonAdd />}>Invite</Button>
                </Grid>
                {/* <Grid item justifyContent="flex-end" xs={6}>
                    <Typography color="gray" style={{textAlign: "right"}}>{`${user_ids.length} of ${userLimit} participants`}</Typography>
                </Grid> */}
            </Grid>
            <Dialog open={open} onClose={dismiss}>
                <DialogTitle>Invite people to the group</DialogTitle>
                <DialogContent>
                    { content }
                </DialogContent>
                <DialogActions>
                    <Button variant="flat" onClick={dismiss}>Dismiss</Button>
                    <Button 
                        variant="contained" 
                        onClick={create} 
                        disabled={creating || created}
                        startIcon={<InsertLink />}>Create Invite Link</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

ParticipantInviter.defaultProps = {
    groupId: null,
    user_ids: [],
    userLimit: 5
}