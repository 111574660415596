import React, { useState } from 'react';
import { TextField, Button, RadioGroup, FormControl, FormControlLabel, Radio, Divider } from '@mui/material';
import { createUserWithEmailAndPassword, getAuth, sendPasswordResetEmail, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { PersonAdd } from '@mui/icons-material';
import { getAndStoreFCMToken } from '../utils/messaging_helper';


export default function LoginForm({ redirect }) {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [passwordConfirm, setPasswordConfirm] = useState("");
    const [signingIn, setSigningIn] = useState(false);
    const [showPasswordReset, setShowPasswordReset] = useState(false);
    const [loginType, setLoginType] = useState("login");
    const { enqueueSnackbar } = useSnackbar()
    const navigate = useNavigate();
    const auth = getAuth();

    const changePassword = (e) => {
        setPassword(e.currentTarget.value)
    }

    const changePasswordConfirm = (e) => {
        setPasswordConfirm(e.currentTarget.value)
    }

    const changeEmail = (e) => {
        setEmail(e.currentTarget.value)
    }

    const login = () => {
        setSigningIn(true);
        signInWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
            const user = userCredential.user;
            finishSignInOrRegister(user);
        })
        .catch((error) => {
            console.log(error);
            enqueueSnackbar("Problem signigin in");
            setSigningIn(false);
            setShowPasswordReset(true);
        });   
    }

    const finishSignInOrRegister = (user) => {
        console.log(user)
        setSigningIn(false);
        // getAndStoreFCMToken(user, () => {});
        navigate(redirect);
    }

    const register = () => {
        if (password === passwordConfirm) {
            createUserWithEmailAndPassword(auth, email, password)
            .then((userCredential) => {
                // Signed in 
                const user = userCredential.user;
                enqueueSnackbar("You're registered!");
                finishSignInOrRegister(user);
            })
            .catch((error) => {
                const errorMessage = error.message;
                enqueueSnackbar(errorMessage);
                
            });            
        } else {
            enqueueSnackbar("Password mismatch")
        }
    }

    const sendPasswordReset = () => {
        const auth = getAuth();
        if (email.length > 0) {
            sendPasswordResetEmail(auth, email)
            .then(() => {
                // Password reset email sent!
                enqueueSnackbar("Email sent!");
            })
            .catch((error) => {
                // ..
            });
        } else {
            enqueueSnackbar("Enter your email then click the reset button.");
        }
    }

    const canSignin = email.length > 0 && password.length > 0;
    const canRegister = email.length > 0 && password.length > 0 && passwordConfirm.length > 0;

    return (
        <div>

            <FormControl>
                <RadioGroup
                    name="controlled-radio-login-type"
                    value={loginType}
                    onChange={(e) => setLoginType(e.target.value)}
                >
                    <FormControlLabel value="login" control={<Radio />} label="Login" />
                    <FormControlLabel value="register" control={<Radio />} label="Register new account" />
                </RadioGroup>
            </FormControl>

            <TextField fullWidth={true}
                margin="dense"
                name="email"
                placeholder="Email address"
                value={email}
                onChange={changeEmail} />
            <TextField fullWidth={true}
                margin="dense"
                name="password"
                type="password"
                placeholder="Password"
                value={password}
                onChange={changePassword} />
            
            <div hidden={loginType == "login"}>
                <TextField fullWidth={true}
                    margin="dense"
                    name="password_repeat"
                    type="password"
                    placeholder="Re-Enter Password"
                    value={passwordConfirm}
                    onChange={changePasswordConfirm} />
            </div>

            <div hidden={!showPasswordReset}>
                <Button onClick={sendPasswordReset}>
                    Forgot your password? Send a reset link.
                </Button>
            </div>

            <Divider style={{margin: 30}}/>
            
            { loginType === "login" ? 
                <Button 
                    variant="contained" 
                    onClick={() => login()} 
                    disabled={!canSignin || signingIn}>Sign In</Button> :
                <Button 
                    variant="contained" 
                    onClick={() => register()} 
                    startIcon={<PersonAdd />}
                    disabled={!canRegister || signingIn}>Register Account</Button>
            }
    

        </div>
    );
}

LoginForm.defaultProps = {
    redirect: "/app"
}