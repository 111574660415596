

export const searchOpenLibrary = (query) => {
    // Docs: https://openlibrary.org/dev/docs/api/search
    return new Promise((resolve, reject) => {
        var requestOptions = {
            method: 'GET',
            redirect: 'follow',
            headers: {
                'Accept': 'application/json'
            }
          };
          
        fetch(`https://openlibrary.org/search.json?q=${query}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                console.log(result)
                resolve(result)
            })
            .catch(error => console.log('error', error));
    })
}