import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useSnackbar } from "notistack";
import { Check, Info, ThumbUp } from "@mui/icons-material";
import { pollVoteUpOption, updateGroup } from "../utils/db_helper";


export default function BookOptionMenu({ option, user, groupId, canVote }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const voteUpOption = () => {
        pollVoteUpOption(user, groupId, option.id).then((res) => {
            console.log(res);
            if (res.success) {
                enqueueSnackbar(`You voted for '${option.title}'`);
            } else if (!res.success && res.message) {
                enqueueSnackbar(res.message)
            }
        })
    }

    const selectAsGroupsBook = () => {
        const data = {
            book: option.book
        }
        updateGroup(groupId, data).then((res) => {
            console.log("Updated");
        })
    }

    const gotoInfoPage = (key) => {
        window.open(`https://openlibrary.org${key}`, '_blank');
    }

    let options = [
        <MenuItem key="select" onClick={() => selectAsGroupsBook()}>
            <ListItemIcon><Check fontSize="small" /></ListItemIcon>
            <ListItemText primary="Select as group's book" />
        </MenuItem>,
    ]

    if (option.book.key != null) {
        options.push(<MenuItem key="info"  onClick={() => gotoInfoPage(option.book.key)}>
            <ListItemIcon><Info fontSize="small" /></ListItemIcon>
            <ListItemText primary="Book info" />
        </MenuItem>);
    }

    if (canVote) {
        options.push(
            <MenuItem key="vote" onClick={() => voteUpOption()}>
                <ListItemIcon><ThumbUp fontSize="small" /></ListItemIcon>
                <ListItemText primary="Vote" />
            </MenuItem>
        )
    }

    return (
        <div>
            <IconButton
                aria-label="more"
                id="long-button"
                disabled={options.length == 0}
                onClick={handleClick}
            >
                <MoreVertIcon />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                >
                { options }
            </Menu>
        </div>
    );
}