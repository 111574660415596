import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fab, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';
import React, { useState } from 'react';
import { createGroup } from '../utils/db_helper';
import { GroupAdd } from '@mui/icons-material';
import BookSelector from './BookSelector';
import { useNavigate } from 'react-router-dom';


export default function GroupCreator({ user }) {
    const [loading, setLoading] = useState(false)
    const [open, setOpen] = useState(false)
    const [groupType, setGroupType] = useState("book");
    const [book, setBook] = useState(null)
    const navigate = useNavigate();

    const createNewGroup = () => {
        console.log(book);
        createGroup(user, groupType, book).then((group) => {
            console.log(group)
            navigate(`/group/${group.id}`);
        })
    }

    const handleTypeChange = (event) => {
        setGroupType(event.target.value);
      };

    const dismiss = () => {
        setOpen(false);
    }

    let content;
    let canCreate = false;
    if (groupType === "book") {
        content = (
            <div>
                <Typography>Create group with a book pre-selected.</Typography>
                <BookSelector onBookSelected={(book) => setBook(book)} />
            </div>
        )
        canCreate = book !== null;
    } else if (groupType == "poll") {
        content = (
            <Typography>Create group with an open poll so anyone in the group can suggest a book.</Typography>
        )
        canCreate = true;
    }

    return (
        <div>
            <Dialog open={open} onClose={dismiss} fullWidth maxWidth="sm">
                <DialogTitle>
                    Create a new private group
                </DialogTitle>
                <DialogContent>
                    <FormControl>
                        <FormLabel id="group-type-radio-label">Group type</FormLabel>
                        <RadioGroup
                            row
                            name="group-type-radio"
                            value={groupType}
                            onChange={handleTypeChange}
                        >
                            <FormControlLabel value="book" control={<Radio />} label="Choose Book" />
                            <FormControlLabel value="poll" control={<Radio />} label="Open Poll" />
                        </RadioGroup>
                    </FormControl>

                    { content }

                </DialogContent>
                <DialogActions>
                    <Button onClick={dismiss}>Cancel</Button>
                    <Button onClick={createNewGroup} variant="contained" disabled={!canCreate}>Create</Button>
                </DialogActions>
            </Dialog>

            <Box
                display="flex" 
                justifyContent="flex-end"
                alignItems="flex-end">
                <Fab variant="extended" style={styles.fab} onClick={() => setOpen(true)} color="primary" >
                    <GroupAdd sx={{ mr: 1 }} />
                    New Group
                </Fab>                    
            </Box>
        </div>
    )
}

GroupCreator.defaultProps = {
 
}

const styles = {
    fab: {
        marginTop: 10
    }
}