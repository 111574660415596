import { AppBar, Box, CssBaseline, Toolbar, Typography } from '@mui/material';
import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom'
import NavBar from './NavBar';
import AppConstants from '../constants/AppConstants';
import app from '../config/firebase';

const TopBar = ({ transparent, disableGutters }) => {
  const navigate = useNavigate();

  const content = (
    <Toolbar disableGutters={disableGutters}>
      <img src="/MarginaliaIconWhite.svg" 
        alt={AppConstants.SITENAME} 
        onClick={() => navigate("/")}
        style={styles.logo} width={25} />
      <Typography variant="h6" sx={{ flexGrow: 1, cursor: "pointer" }} onClick={() => navigate("/")}>
          { AppConstants.SITENAME }
      </Typography>
      <NavBar />
  </Toolbar>
  )
  return (
    <Box sx={{ flexGrow: 1 }}>
        <CssBaseline />
        { transparent ? <>{content}</> : <AppBar position="sticky">{content}</AppBar> }
    </Box>
  )
}

TopBar.defaultProps = {
  disableGutters: false
}

const styles = {
  logo: {
    marginRight: 15,
    cursor: "pointer"
  }
}
export default TopBar