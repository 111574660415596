import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import app from '../config/firebase';
import { getAuth, signOut } from 'firebase/auth';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Avatar, Button, Divider, ListItemIcon, MenuItem } from '@mui/material';
import AccountNavMenu from './AccountNavMenu';
const auth = getAuth(app);

function NavBar({ page }) {

  const [user, loading, error] = useAuthState(auth);

  const navigate = useNavigate()

  const signedIn = user != null;

  const COMMON_MENU = [
    { name: "About", id: "about", to: "/about" },
  ]
  const SIGNED_IN_MENU = [
    { name: "Groups", id: "home", to: "/app" },
  ]

  const SIGNED_OUT_MENU = [];

  let menu = COMMON_MENU;
  menu = menu.concat(signedIn ? SIGNED_IN_MENU : SIGNED_OUT_MENU);

  return (
    <div className="NavBar">
        { menu.map((mi, i) => {
          let cls = ""
          return <Button onClick={() => navigate(mi.to || mi.id)} className={cls} color="inherit" key={i}>{mi.name}</Button>
        })}
        { !signedIn ? <Button onClick={() => navigate("/login")} color="inherit" key="login">Login</Button> : null }
        { signedIn ? <AccountNavMenu user={user} /> : null }
    </div>
  )
}


export default NavBar