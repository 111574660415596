import ls from 'localstorage-slim';
import AppConstants from '../constants/AppConstants';
import {merge, union} from 'lodash';

export const cacheUserLookup = (lookup) => {
    const lsPrefix = AppConstants.LS_KEY_USER_LOOKUP;
    ls.set(lsPrefix, {...lookup}, {ttl: 60*60*24}) // 24 hrs
}

export const getUserLookup = () => {
    const lsPrefix = AppConstants.LS_KEY_USER_LOOKUP;
    return ls.get(lsPrefix);
}

export const updateInUserCache = (user_id, displayName) => {
    let lookup = getUserLookup();
    if (lookup == null) {
        lookup = {};
    }
    lookup[user_id] = {displayName};
    cacheUserLookup(lookup);
}
